import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import ImageViewer from './ImageViewer';
import {
  useGetFileByIdMutation,
  useGetAllSlideAnnotationsQuery
} from 'services/slides';
import convert from 'xml-js';
import FileProcessingDialog from './FileProcessingDialog';
import ErrorFileProcessDialog from './ErrorFileProcessDialog';
import { generateTileSourcesFromXML } from 'utils/appHelpers';

const SlideViewer = () => {
  const googleApiUrl = process.env.REACT_APP_GOOGLE_CLOUD_STORAGE;
  const [slide, setSlide] = useState(null);
  const [annotationsData, setAnnotationsData] = useState([]);
  const [tileSources, setTileSources] = useState(null);
  const [maxZoomLevel, setMaxZoomLevel] = useState(16);
  const [isLoaded, setIsLoaded] = useState(false);
  const [processDialog, setProcessDialog] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false);
  const [selectAnnotation, setSelectAnnotation] = useState([])

  const { id: fileId } = useParams();
  const [getFileById, { isSuccess }] = useGetFileByIdMutation(fileId);
  const {
    data,
    isFetching: isLoadingAnno,
    refetch: refetchAnnotations,
    error
  } = useGetAllSlideAnnotationsQuery(fileId, { skip: !isSuccess });
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    const getFile = async (fileId) => {
      try {
        const { data, success } = await getFileById(fileId).unwrap();
        if (!data.gcsFileName && !data.tilePath) {
          navigate('/');
        }
        if (success) {
          let tileSources = {};
          setSlide(data);
          localStorage.setItem('color', JSON.stringify(data.settings.color || "black"));
          setProcessDialog(true);
          const {
            height: slideHeight,
            width: slideWidth,
            lcmData,
            tilePath
          } = data;
          let xmlData = null;
          let imageUrl = '';
          if (tilePath) {
            const {
              tiles: { tileHeight, tileWidth, zoomLevel }
            } = lcmData;

            imageUrl =
              'https://storage.googleapis.com/' +
              tilePath
                .split('/')
                .map((c) => encodeURIComponent(c))
                .join('/');

            const options = {
              imageUrl,
              maxLevel: 12,
              xmlData: {
                _declaration: {
                  _attributes: {
                    version: '1.0',
                    encoding: 'UTF-8'
                  }
                },
                Image: {
                  _attributes: {
                    xmlns: 'http://schemas.microsoft.com/deepzoom/2008',
                    Format: 'webp',
                    Overlap: '0',
                    TileHeight: tileHeight,
                    tileWidth: tileWidth
                  },
                  Size: {
                    _attributes: {
                      Height: slideHeight,
                      Width: slideWidth
                    }
                  }
                }
              },
              getTileWidth: () => {
                return tileWidth;
              },
              getTileHeight: () => {
                return tileHeight;
              }
            };

            tileSources = generateTileSourcesFromXML(options);
            setMaxZoomLevel(zoomLevel);
          } else {
            const xmlText = await fetch(
              `${googleApiUrl}/${encodeURIComponent(data.gcsFileName)}/meta.dzi?random=${Date.now()}`,
              {
                method: 'GET'
              }
            ).then((r) => r.text());
            xmlData = JSON.parse(convert.xml2json(xmlText, { compact: true }));
            if(xmlData?.Error?.Code?._text === "NoSuchKey"){
              throw {
                status:404
              }
            }
            
            imageUrl = `${googleApiUrl}/${encodeURIComponent(data.gcsFileName)}/`;
            tileSources = generateTileSourcesFromXML({ imageUrl, xmlData });
          }
          setTileSources(tileSources);
          return Promise.resolve(true);
        }
        return Promise.resolve(false);
      } catch (err) {
        if (err.status === 403) {
          enqueueSnackbar(
            err.data.error,
            {
              variant: 'error'
            }
          );
          setTimeout(() => (navigate(`/slidebox`)), 3000);
        } 
        if (err.status === 404) {
          enqueueSnackbar(
            'Current image is unavailable. You will be redirected to Slide box',
            {
              variant: 'error'
            }
          );
          setTimeout(() => (navigate(`/slidebox`)), 3000);
        } 
        if (err.status === 401) {
          enqueueSnackbar(
            'This slide is not accessable. You will be redirected to Slide box',
            {
              variant: 'error'
            }
          );
          setTimeout(() => (navigate(`/slidebox`)), 3000);
        } else {
          console.log(err);
        }
        return;
      }
    };

    const init = async (fileId) => {
      setIsLoaded(false);
      await getFile(fileId);
    };

    if (fileId) {
      init(fileId);
    }
  }, [fileId]);

  useEffect(() => {
    if (!isLoadingAnno && data) {
      setAnnotationsData(data.data);
      setIsLoaded(true);
      setErrorDialog(false);
      setProcessDialog(false);
    } else if (error) {
      setErrorDialog(true);
    }
  }, [data, isLoadingAnno, error]);

  return (
    <>
      {
        <FileProcessingDialog
          open={processDialog}
          handleOpen={setProcessDialog}
        />
      }
      {
        <ErrorFileProcessDialog
          open={errorDialog}
          handleOpen={setErrorDialog}
        />
      }
      {isLoaded && slide && tileSources && (
        <ImageViewer
          maxZoomLevel={maxZoomLevel}
          slideId={fileId}
          tileSources={tileSources}
          slide={slide}
          annotationsData={annotationsData}
          selectAnnotation={selectAnnotation}
          setSelectAnnotation={setSelectAnnotation}
          refetchAnnotations={refetchAnnotations}
        />
      )}
    </>
  );
};
export default SlideViewer;
