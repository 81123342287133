import OpenSeadragon from 'openseadragon';
import { useEffect } from 'react';
import { copyAnnotationsToNewPosition, initSelectedAnno, moveAnnotationsToNewPosition } from 'utils/shape.helper';

export const useCanvasEvent = (
    viewer,
    selectAnnotation,
    anno,
    mulSelectMode,
    handleSwitchMulSelect,
    setSelectAnnotation,
    isSelectedAnnotation,
    setIsSelectedAnnotation,
    currentAnnotation,
    setCurrentAnnotation,
    handleUpdateAnnotation,
    handleCreateAnnotation,
    showAnnoDeleteAlert
) => {
    useEffect(() => {
        let mouseX = 0
        let mouseY = 0
        const handleCanvasMove = (event) => {
            mouseX = event.offsetX
            mouseY = event.offsetY
        }
        const handleCanvasDrag = (event) => {
            if (event.direction !== 0 && isSelectedAnnotation && !mulSelectMode && selectAnnotation.length) {
                const viewportPoint = viewer.viewport.pointFromPixel(event.position);
                const imagePoint = viewer.viewport.viewportToImageCoordinates(viewportPoint.x, viewportPoint.y);
                const allAnotations = anno.getAnnotations();
                const currentSelectedAnno = selectAnnotation.find(v => v._id === currentAnnotation._id)
                const movedAnnotations = moveAnnotationsToNewPosition(currentSelectedAnno, selectAnnotation, imagePoint.x, imagePoint.y, true)
                const res = Array.from(new Set(allAnotations.concat(movedAnnotations).map(item => item._id)))
                    .map(_id => {
                        const foundA = allAnotations.find(item => item._id === _id);
                        const foundB = movedAnnotations.find(item => item._id === _id);
                        return foundB ? { ...foundB, id: foundB._id } : { ...foundA, id: foundA._id };
                    });
                anno.setAnnotations([...res]);
                setSelectAnnotation(movedAnnotations)
                initSelectedAnno(movedAnnotations)
            }

        }
        const handleCanvansPan = (event) => {
            if (isSelectedAnnotation) {
                viewer.panHorizontal = false;
                viewer.panVertical = false;
            }
        }
        const handleCanvasDragEnd = (event) => {
            if (isSelectedAnnotation) {
                viewer.panHorizontal = true;
                viewer.panVertical = true;
                const updatePositionAnnotations = anno.getAnnotations().filter(anno => anno?.update && !anno?.parentCopy)
                if (selectAnnotation.length && updatePositionAnnotations.length) {
                    if (selectAnnotation.length) {
                        handleUpdateAnnotation(updatePositionAnnotations)
                    }
                    anno.setAnnotations(anno.getAnnotations().map(v => ({ ...v, update: false })))
                }
                setIsSelectedAnnotation(false)
            }
        }
        const handleEventKeyDown = (event) => {
            //event delete acction
            if (event.keyCode === 8 || event.keyCode === 46) {
                selectAnnotation.length && showAnnoDeleteAlert(selectAnnotation)
            }
            if(anno.getSelected !== undefined){
                if (event.ctrlKey || event.metaKey) {
                    handleSwitchMulSelect(true)
                }
            }
            else {
                // event ctrl || meta (macos)
                if (event.ctrlKey || event.metaKey) {
                    handleSwitchMulSelect(true)
                    anno.selectAnnotation();
                    if (event.key === 'a') {
                        const allAnotations = anno.getAnnotations();
                        setSelectAnnotation(allAnotations)
                    }
                    // event ctrl + c || meta +c (macos)
                    if (event.key === 'c') {
                        setSelectAnnotation(selectAnnotation.map(v => ({ ...v, select: true })))
                    }
                    // event ctrl + v || meta +v (macos)
                    if (event.key === 'v') {
                        const allAnotations = anno.getAnnotations();
                        const annoSelected = selectAnnotation.filter(v => v.select)
                        if (!annoSelected.length) return
                        const pointer = new OpenSeadragon.Point(mouseX, mouseY);
                        const viewportPoint = viewer.viewport.pointFromPixel(pointer);
                        const imagePoint = viewer.viewport.viewportToImageCoordinates(viewportPoint.x, viewportPoint.y);
                        const movedAnnotations = copyAnnotationsToNewPosition(annoSelected, imagePoint.x, imagePoint.y)
                        anno.setAnnotations([...movedAnnotations, ...allAnotations,]);
                        setSelectAnnotation(movedAnnotations)
                        movedAnnotations.length && setCurrentAnnotation(movedAnnotations[0])
                        initSelectedAnno(movedAnnotations)
                        //case create annotation copy
                        const createAnnotationsCopy = movedAnnotations.map(anno => {
                            delete anno._id
                            delete anno._id
                            return anno
                        })
                        createAnnotationsCopy.length && handleCreateAnnotation(createAnnotationsCopy, null, movedAnnotations)
                    }

                }
            }
        }

        const handleEventKeyUp = (event) => {
            if (event.key === 'Control' || event.key === "Meta") {
                handleSwitchMulSelect(false)
            }
        }
        if (viewer) {
            const canvas = viewer.canvas;
            canvas && canvas.addEventListener('mousemove', handleCanvasMove);
            viewer.addHandler('canvas-drag', handleCanvasDrag);
            viewer.addHandler('canvas-release', handleCanvasDragEnd);
            viewer.addHandler('pan', handleCanvansPan);

            document.addEventListener('keydown', handleEventKeyDown)
            document.addEventListener('keyup', handleEventKeyUp);
            return () => {
                viewer.removeHandler("canvas-drag", handleCanvasDrag)
                viewer.removeHandler("canvas-release", handleCanvasDragEnd)
                viewer.removeHandler("pan", handleCanvansPan)
                document.removeEventListener('keydown', handleEventKeyDown)
                document.removeEventListener('keyup', handleEventKeyUp)
            };
        }
    }, [viewer, selectAnnotation, anno, isSelectedAnnotation, mulSelectMode, currentAnnotation]);
};
