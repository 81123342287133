import StringNetwork from './proteinView';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { uuidv4 } from '@firebase/util';
import { Container, TableCell, TableRow, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import CustomTable from '../../../components/Table';
import { useEffect, useMemo, useState } from 'react';

const AnalysisPopUp = ( { biomarkers, isFetching } ) => {
  const theme = useTheme();
  const [ proteinSelected, setProteinSelected ] = useState( biomarkers?.[ 0 ]?.parsedName.split( ' ' )[ 0 ] );

  const headCells = useMemo( () => {
    if ( !biomarkers || biomarkers.length === 0 ) return [];
    return Object.keys( biomarkers.biomarkerData[ 0 ] ).map( ( key ) => ( {
      id: key,
      label: key,
      sortable: true,
    } ) );
  }, [ biomarkers ] );
  // Seleccionar la primera proteína cuando los biomarkers cambian
  useEffect( () => {
    if ( biomarkers && biomarkers.length > 0 && biomarkers[ 0 ]?.parsedName ) {
      const [ firstProtein ] = biomarkers[ 0 ].parsedName.split( ' ' );
      setProteinSelected( firstProtein );
    }
  }, [ biomarkers, setProteinSelected ] );

  const handleClickRow = ( biomarker ) => {
    if ( biomarker?.parsedName ) {
      const [ firstProtein, second ] = biomarker.parsedName.split( ' ' );
      setProteinSelected( firstProtein + '%0d' + second );
      // setProteinSelected( biomarker.parsedName.split( ' ' ).join( '%0d' ) );
    }
  };


  return (
    <Container maxWidth="100%">
      {typeof biomarkers != 'undefined' ? (
        <TabContext value={'0'}>
          <TabPanel value="0" key="0" className='analysis-graph-container'>
          {/* <TabPanel value="0" key="0"> */}
            {proteinSelected && <StringNetwork identifier={proteinSelected} />}
            <CustomTable
              rows={biomarkers.biomarkerData}
              headCells={headCells}
              isLoading={isFetching}
              pagingMarginRight="48px"
              // style={{ width: proteinSelected ? '50%' : '100%', height: 'auto' }}
            >
              {( head, items ) =>
                items.map( ( biomarker ) => (
                  <TableRow hover={true} key={uuidv4()} onClick={() => handleClickRow( biomarker )}>
                    {Object.keys( biomarker ).map( ( attr ) => (
                      <TableCell align="left" key={uuidv4()}>
                        <Box
                          sx={{
                            textAlign: 'left',
                            [ theme.breakpoints.down( 'sm' ) ]: {
                              maxWidth: '60px'
                            }
                          }}
                        >
                          {biomarker[ attr ]}
                        </Box>
                      </TableCell>
                    ) )}
                  </TableRow>
                ) )
              }
            </CustomTable>
          </TabPanel>
        </TabContext>
      ) : (
        <Box
          sx={{ textAlign: 'center', color: theme.palette.text.textGray1 }}
          mt={1}
        >
          {isFetching ? 'Loading analysis data..' : 'No analysis data found'}
        </Box>
      )}
    </Container>
  );
};

export default AnalysisPopUp;
