import {
  Folder,
  CreateNewFolder,
  InsertPhoto,
  FolderShared
} from '@mui/icons-material'

export const ROLES = {
  SUPER_ADMIN: 'superadmin',
  ADMIN: 'admin',
  MANAGER: 'manager',
  USER: 'user',
  COLLABORATOR: 'collaborator'
}
export const ROLE_NAMES = {
  superadmin: 'Super Admin',
  admin: 'Admin',
  manager: 'Manager',
  collaborator: 'Collaborator',
  user: 'User'
}
export const FILE_TYPES = {
  FOLDER: 'folder',
  CASE: 'case',
  FILE: 'file'
}
export const FILE_ICON = {
  folder: Folder,
  file: InsertPhoto,
  case: CreateNewFolder,
  folderUsername: FolderShared
}
export const DEFAULT_ROOT_FOLDER_NAME = 'Slidebox'
export const MANAGED_BY_ME_FOLDER_NAME = 'Managed by me'
export const SHARED_WITH_ME_FOLDER_NAME = 'Shared with me'

export const REQUEST_TYPES = {
  Invite: 'invite',
  ResetPassword: 'reset-password',
  ChangePassword: 'change-password',
  Activate: 'activate'
}
export const SHAPE_TYPES = {
  rect: 'rectangle',
  ellipse: 'ellipse',
  polygon: 'freehand',
  Freehand: 'freehand'
}

export const DATE_FILTER_VALUES = {
  Today: 'Today',
  Yesterday: 'Yesterday',
  PastWeek: 'Past week',
  PastMonth: 'Past month'
}
