import React from 'react';
import { Container, useTheme } from '@mui/material';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Box } from '@mui/system';
import { useState } from 'react';
import BeeSwarmGraph from './BeeSwarmGraph';

const BioMarkerTab = ({
   biomarker,
   compBiomarker,
   openedFor,
   compAnnoColor
}) => {
  const theme = useTheme();
  const [value, setValue] = useState('0');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  let dataSetValue = [];
  let compDataSetValue = [];
  let allBiomarkerKey = [];
  let keys = [];
  let typeOnekeys = [
    'Coverage [%]',
    '# Peptides',
    '# PSMs',
    '# Unique Peptides',
    '# AAs',
    'MW [kDa]',
    // 'calc. pI',
    'Score Sequest HT: A2 Sequest HT'
  ];
  let typeTwokeys = ['Value'];
  let fileType = 'typeOne';
  if (biomarker.biomarkerData?.length > 0) {
    keys = Object.keys(biomarker.biomarkerData[0]);
    if (keys.includes('Accession')) {
      keys = typeOnekeys;
      fileType = 'typeOne';
    } else {
      keys = typeTwokeys;
      fileType = 'typeTwo';
    }

    allBiomarkerKey[biomarker.cap] = keys;

    keys.forEach((key) => {
      dataSetValue[key] = [];
      compDataSetValue[key] = [];
    });
    biomarker.biomarkerData.forEach((bData) => {
      keys.forEach((key) => {
        let tempObject = {};
        if (fileType === 'typeOne') {
          tempObject = {
            Accession: bData.Accession,
            Description: bData.Description
          };
        } else if (fileType === 'typeTwo') {
          tempObject = {
            Description: bData.Antibody
          };
        }
        tempObject[key] = bData[key];
        dataSetValue[key].push(tempObject);
      });
    });
    if (openedFor === 'comp' && compBiomarker && compBiomarker.biomarkerData) {
      compBiomarker.biomarkerData.forEach((bData) => {
        keys.forEach((key) => {
          let tempObject = {};
          if (fileType === 'typeOne') {
            tempObject = {
              Accession: bData.Accession,
              Description: bData.Description
            };
          } else if (fileType === 'typeTwo') {
            tempObject = {
              Description: bData.Antibody
            };
          }
          tempObject[key] = bData[key];
          compDataSetValue[key].push(tempObject);
        });
      });
    }
    const dataSetKey = Object.keys(dataSetValue);
  }
  return (
    <Container maxWidth="100%" style={{ overflowX: 'scroll !important' }}>
      {biomarker ? (
        <TabContext value={value}>
          <TabList onChange={handleChange}>
            {keys.map((key, index) => (
              <Tab label={key} value={index.toString()} key={index} />
            ))}
          </TabList>
          {keys.map((key, index) => (
            <TabPanel value={index.toString()} key={index}>
              <BeeSwarmGraph
                biomarkerData={dataSetValue[key]}
                biomarkerName={key}
                compBiomarkerData={compDataSetValue[key]}
                compAnnoColor={compAnnoColor}
              />
            </TabPanel>
          ))}
        </TabContext>
      ) : (
        <Box
          sx={{ textAlign: 'center', color: theme.palette.text.textGray1 }}
          mt={1}
        >
          {biomarker ? 'Loading analysis data..' : 'No analysis data found'}
        </Box>
      )}
    </Container>
  );
};

export default BioMarkerTab;
