import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material';
import Avatar from 'components/Avatar';
import moment from 'moment';
import { getMentionText } from 'utils/mentionHelper';
import EditIcon from '@mui/icons-material/Edit';
import { useAuth } from 'hooks/useAuth';
import CancelIcon from '@mui/icons-material/Cancel';
import CommentDeleteButton from './CommentDeleteButton';
import AddIcon from '@mui/icons-material/Add';
import SyncIcon from '@mui/icons-material/Sync';
import React, { useEffect } from 'react';

const googleApiUrl = process.env.REACT_APP_GOOGLE_CLOUD_STORAGE;

const CommentList = ({
  onDelete,
  isLoading,
  editId,
  onEdit,
  onCancelEdit,
  data
}) => {
  const commentRef = React.useRef(null);
  const {
    user: { _id: uid }
  } = useAuth();

  const disableEditButton = (
    <Button
      size="small"
      color="buttonGray"
      sx={{ minWidth: 'unset' }}
      variant="contained"
      display="none"
      onClick={onCancelEdit}
    >
      <CancelIcon fontSize="xs" />
    </Button>
  );

  // Scroll to the bottom of the comment list
  useEffect(() => {
    if (commentRef.current) {
      commentRef.current.scrollTop = commentRef.current.scrollHeight;
    }
  }, [data]);


  const renderActionButtons = (comment) => {
    if (uid !== comment.createdBy?._id) return null;
    if (editId && editId === comment._id) {
      return disableEditButton;
    }

    return (
      <>
        <Button
          size="small"
          color="success"
          display="none"
          sx={{ minWidth: 'unset' }}
          variant="contained"
          onClick={onEdit(comment.id)}
        >
          <EditIcon fontSize="xs" />
        </Button>
        <CommentDeleteButton id={comment.id} onDelete={onDelete} />
      </>
    );
  };

  return (
    <Stack
      ref={commentRef}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        width: '100%'
      }}
      spacing={1}
      p={1}
    >
      {data?.map((comment) => {
        if (comment.createdBy?._id === uid)
          return (
            <Stack
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              marginLeft="auto"
              gap={0.5}
              width="100%"
              key={comment._id}
              spacing={0.5}
            >
              <Stack
                id={`comment-${comment._id}`}
                display="flex"
                flexDirection="column"
                alignItems="flex-end"
                position="relative"
                maxWidth="90%"
                gap={0.5}
                sx={{
                  '&:hover': {
                    '& .comment-actions': {
                      display: 'flex'
                    }
                  }
                }}
              >
                <Typography
                  variant="body1"
                  fontSize="0.85rem"
                  backgroundColor="blue.main"
                  color="white"
                  minWidth="65px"
                  padding={1}
                  boxShadow={5}
                  sx={{
                    borderRadius: '10px 2px 10px 10px',
                    wordBreak: 'break-word'
                  }}
                  whiteSpace="pre-wrap"
                >
                  {getMentionText(comment.content)}
                </Typography>
                <Typography variant="body2" fontSize="0.625rem">
                  {moment(comment.createdAt).fromNow()}
                </Typography>
                <Stack
                  position="absolute"
                  direction="row"
                  alignItems="center"
                  gap={0.5}
                  display="none"
                  className="comment-actions"
                  justifyContent="flex-end"
                  bottom={5}
                  left={0}
                >
                  {renderActionButtons(comment)}
                </Stack>
                {comment.file && (
                <div>
                  <a
                   href={`${googleApiUrl}/${comment.file.fileUrl}`}
                   download={comment.file.fileName}
                   target="_blank"
                   rel="noopener noreferrer"
                   style={{
                    display: 'inline-block',
                    maxWidth: '75%',
                    wordWrap: 'break-all',
                    whiteSpace: 'pre-wrap'
                    }}
                  >
                    {comment.file.fileName}
                  </a>
                </div>
                )}
              </Stack>
            </Stack>
          );

        return (
          <Stack
            display="flex"
            flexDirection="row"
            justifyContent="flex-start"
            marginLeft="auto"
            gap={0.5}
            width="100%"
            key={comment._id}
            spacing={0.5}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
              sx={{
                paddingBottom: '15px'
              }}
            >
              <Avatar src={comment.createdBy?.avatar} size={40} />
            </Box>
            <Stack
              id={`comment-${comment._id}`}
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              position="relative"
              maxWidth="90%"
              gap={0.5}
              sx={{
                '&:hover': {
                  '& .comment-actions': {
                    display: 'flex'
                  }
                }
              }}
            >
              <Typography fontWeight={500} fontSize="0.625rem">
                {`${comment.createdBy.firstName || ''} ${
                  comment.createdBy.lastName || ''
                }`.trim()}
              </Typography>
              <Typography
                variant="body1"
                fontSize="0.85rem"
                backgroundColor="gray.main"
                padding={1}
                minWidth="65px"
                boxShadow={5}
                sx={{
                  borderRadius: '2px 10px 10px 10px'
                }}
                whiteSpace="pre-wrap"
              >
                {getMentionText(comment.content)}
              </Typography>
              <Typography variant="body2" fontSize="0.625rem">
                {moment(comment.createdAt).fromNow()}
              </Typography>
              <Stack
                position="absolute"
                direction="row"
                alignItems="center"
                gap={0.5}
                display="none"
                className="comment-actions"
                justifyContent="flex-end"
                bottom={5}
                right={0}
              >
                {comment.hasAddAction && (
                  <Button
                    size="small"
                    color="success"
                    sx={{ minWidth: 'unset' }}
                    variant="contained"
                  >
                    <AddIcon fontSize="xs" />
                  </Button>
                )}
                {comment.hasSyncAction && (
                  <Button
                    size="small"
                    color="info"
                    sx={{ minWidth: 'unset' }}
                    variant="contained"
                  >
                    <SyncIcon fontSize="xs" />
                  </Button>
                )}
              </Stack>
              {comment.file && (
              <div>
                <a
                 href={`${googleApiUrl}/${comment.file.fileUrl}`}
                 download={comment.file.fileName}
                 target="_blank"
                 rel="noopener noreferrer"
                 style={{
                  display: 'inline-block',
                  maxWidth: '75%',
                  wordWrap: 'break-all',
                  whiteSpace: 'pre-wrap'
                  }}
                >
                  {comment.file.fileName}
                </a>
              </div>
            )}
            </Stack>
          </Stack>
        );
      })}
      {isLoading && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%'
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Stack>
  );
};

export default CommentList;
