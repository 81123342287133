import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  Tooltip,
  Divider
} from '@mui/material';

import { useGetProjectDetailsMutation } from '../../../../services/projects';
import { useCopySlideToProjectMutation } from 'services/slides';
import {
  selectCurrentFolderView
} from '../../../../stores/slidebox/slideboxSlice';
import { FormContainer, useForm, Controller } from 'react-hook-form-mui';
import { LoadingButton } from '@mui/lab';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { CustomSelect } from '../../../../components/Shared/Select';
import { useGetAllProjectsMutation } from '../../../../services/projects';
import { useGetFileByIdMutation } from 'services/slides';
import { ROLES } from '../../../../constants';
import { useAuth } from '../../../../hooks/useAuth';
import ErrorDialog from 'components/Dialog/ErrorDialog';

const CopyButton = ({ entity, calledFrom, copyClicked }) => {
  const {
    user: { email: loggedEmail, role: loggedRole }
  } = useAuth();

  const [copySlide, { isLoading: isCopying }] = useCopySlideToProjectMutation();
  const [getAllProjects, { isLoading: isLoadingProjects }] =
    useGetAllProjectsMutation();
  const [copiableProjects, setcopiableProjects] = useState([]);

  const { _id: id } = useSelector(selectCurrentFolderView);

  const { enqueueSnackbar } = useSnackbar();

  const [openCopyDialog, setopenCopyDialog] = useState(false);
  const [disableCopy, setDisableCopy] = useState(false);

  useEffect(() => {
    if (copyClicked > 0) {
      handleOpenCopyDialog();
    }
  }, [copyClicked]);

  const [errorMessage, setErrorMessage] = useState('');

  const onCloseErrorDialog = () => {
    setErrorMessage('');
  };

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      emails: ['']
    }
  });

  const handleOpenCopyDialog = async () => {
    let projects = [];
    let projectRes = [];
    projectRes = await getAllProjects({
    }).unwrap();

    projects = projectRes.data;
    let tempProjects = [];
    projects.forEach((project) => {
      if (typeof(project) !== 'undefined') {
        if (project._id == entity.project)
          return;
        tempProjects.push(project);
      }
    });
    setcopiableProjects(tempProjects);
    setopenCopyDialog(true);
    setCopyButtonState();
  };

  const projects = copiableProjects;

  const handleCloseShareDialog = () => {
    setopenCopyDialog(false);
    reset();
  };

  const handleConfirmCopy = async (form) => {
    try {
      const { _id } = entity;
      const projectId = form.project;
      return copySlide({
        id: entity._id,
        project: projectId
      })
        .unwrap()
        .then((res) => {
          handleCloseShareDialog();
          reset();
          enqueueSnackbar('Copy successfully', { variant: 'success' });      
        });
    } catch (error) {
      const { data } = error;
      setErrorMessage(
        data && typeof data.error === 'string'
          ? data.error
          : 'Something went wrong!'
      );
    }
  };
  const setCopyButtonState = () => {
    let ownItems = true;

    if (entity.createdBy.email !== loggedEmail) {
      ownItems = false;
    }

    if (loggedRole === ROLES.SUPER_ADMIN || loggedRole === ROLES.ADMIN) {
      setDisableCopy(false);
    } else {
      setDisableCopy(!ownItems);
    }
  };
  return (
    <Box style={{ marginLeft: '4px' }}>
      {calledFrom !== 'menu' && (
        <Tooltip title="Copy Slide To">
          <Button variant="outlined" onClick={handleOpenCopyDialog}>
            <ContentCopyIcon
              fontSize="small"
              sx={{ marginRight: '4px' }}
            />{' '}
            Copy To
          </Button>
        </Tooltip>
      )}
      <Dialog open={openCopyDialog} maxWidth="sm" fullWidth keepMounted>
        <FormContainer onSuccess={handleSubmit(handleConfirmCopy)}>
          <Divider />
          <DialogContent sx={{ maxHeight: '400px', overflow: 'auto' }}>
            <Controller
              name="project"
              control={control}
              rules={{ required: 'This field is required' }}
              render={({ field }) => (
                <CustomSelect
                  {...field}
                  fullWidth
                  id="project"
                  aria-describedby="project"
                  SelectDisplayProps={{ style: { padding: '4px 12px' } }}
                  displayEmpty
                  error={!!errors.role}
                  error-message={errors.role?.message}
                  renderValue={(selected) => {
                    if (!selected) {
                      return (
                        <span className="placeholder">Select project</span>
                      );
                    }

                    return projects.find(
                      (project) => project._id === selected
                    ).name;
                  }}
                >
                  {projects.map((project) => {
                    return (
                      <MenuItem value={project._id} key={project._id}>
                        {project.name}
                      </MenuItem>
                    );
                  })}
                </CustomSelect>
              )}
            />
          </DialogContent>
          <Divider />
          <DialogActions>
            {!isCopying && (
              <Button
                variant="contained"
                color="buttonLightGray"
                disableElevation
                onClick={handleCloseShareDialog}
              >
                Cancel
              </Button>
            )}
            {!disableCopy && (
              <LoadingButton
                loading={isCopying}
                type="submit"
                variant="contained"
                color="primary"
                disableElevation
                disabled={disableCopy}
              >
                Copy
              </LoadingButton>
            )}
          </DialogActions>
        </FormContainer>
      </Dialog>
      <ErrorDialog errorMessage={errorMessage} onClose={onCloseErrorDialog} />
    </Box>
  );
};
export default CopyButton;
